import React from "react";
import * as styles from "./footer.module.scss";
import { Link, graphql, useStaticQuery } from "gatsby";

const Footer = () => {
  const data = useStaticQuery(
    graphql`
      query MyQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  );
  return (
    <React.Fragment>
      <div className={styles.footer}>
        <span>
            <Link to="/">{data.site.siteMetadata.title}</Link> ©
            {new Date().getFullYear()}
        </span>
        <span>
          {"  "} Powered By <a target="_blank" rel="noreferrer noopener" href="https://www.sporule.com">Sporule</a>
        </span>
      </div>
    </React.Fragment>
  );
};

export default Footer;
