import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Helmet } from "react-helmet";

const SEO = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            lang
          }
        }
      }
    `
  );
  let meta = data.site.siteMetadata;
  return (
    <Helmet
      titleTemplate={"%s - " + meta.title}
      defaultTitle={meta.title}
      htmlAttributes={{
        lang: meta.lang,
      }}
    >
      <meta charSet="utf-8" />
      <meta name="description" content={meta.description} />
    </Helmet>
  );
};

export default SEO;
