import React, { useState } from "react";
import { Link } from "gatsby";
import * as styles from "./navigation.module.scss";
import { navigate, graphql, useStaticQuery } from "gatsby"

const Nav = () => {
  const data = useStaticQuery(
    graphql`
      query NavQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  );
  const [displayMenu, setDisplayMenu] = useState(false);

  const toggleMenu = () => {
    if (displayMenu) {
      setDisplayMenu(false);
    } else {
      setDisplayMenu(true);
      setTimeout(() => {
        document.addEventListener("click", closeMenu, false);
        document.addEventListener("scroll", closeMenu, false);
      }, 300);
    }
  };

  const closeMenu = (event) => {
    if (event.target.nodeName.toLowerCase() === "input" && event.target.className.includes("search")) {
      document.removeEventListener("scroll", closeMenu, false);
      return;
    }
    else {
      setDisplayMenu(false);
      document.removeEventListener("click", closeMenu, false);
      document.removeEventListener("scroll", closeMenu, false);
    }
  };

  const searchKeyUp = (event) => {
    if (event.key === "Enter") {
      event.preventDefault()
      if (typeof window !== 'undefined') {
        navigate(`/search?q=${event.target.value}`);
        setDisplayMenu(false);
      }
    }
  }


  return (
    <React.Fragment>
      <header className={"row " + styles.topNav}>
        <h1 className="col-lg-1 col-xs-9 ">
          <Link to="/">
            {data.site.siteMetadata.title}
          </Link>
        </h1>
        <nav className={"col-lg-9 " + styles.navList}>
          <ul>
            <li>
              <input className={styles.search} type="text" placeholder="Search" onKeyUp={searchKeyUp} />
            </li>
          </ul>
        </nav>
        <div className={"col-lg-2 col-xs-3 " + styles.extras}>
          <div className={styles.menu}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#000000"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              onClick={toggleMenu}
            >
              <line x1="3" y1="12" x2="21" y2="12"></line>
              <line x1="3" y1="6" x2="21" y2="6"></line>
              <line x1="3" y1="18" x2="21" y2="18"></line>
            </svg>
          </div>
        </div>
      </header>
      <div className={styles.mobileNav}>
        <div className={displayMenu ? styles.activeNav : styles.collapseNav}>
          <ul>
            <li>
              <input className={styles.search} type="text" placeholder="Search" onKeyUp={searchKeyUp} />
            </li>
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Nav;
