import React from "react";
import Nav from "../components/navigation/navigation";
import Footer from "../components/footer/footer";
import SEO from "../components/seo/seo";

const BaseLayout = ({ children }) => {
  return (
    <React.Fragment>
      <SEO />
      <Nav />
      <div className="row">
        <div className="col-lg-offset-1 col-lg-10 col-xs-12">{children}</div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default BaseLayout;
